@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,800,700,600";
@import "https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800";
@import "https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800";
@import "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700";

html,
body {
  height: 100%;
}

body {
  background-color: #170000;
  color: #fff;
}

/* ログイン */

#form-signin-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

#form-signin-container .form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

#form-signin-container .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

#form-signin-container .form-label-group > input,
#form-signin-container .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

#form-signin-container .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #ccc;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

#form-signin-container .form-label-group input,
.form-label-group input:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

#form-signin-container input:-webkit-autofill,
#form-signin-container input:-webkit-autofill:hover,
#form-signin-container input:-webkit-autofill:focus #form-signin-container textarea:-webkit-autofill,
#form-signin-container textarea:-webkit-autofill:hover #form-signin-container textarea:-webkit-autofill:focus,
#form-signin-container select:-webkit-autofill,
#form-signin-container select:-webkit-autofill:hover,
#form-signin-container select:-webkit-autofill:focus {
  border-color: rgba(255, 255, 255, 0.3);
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.1) inset;
  transition: background-color 5000s ease-in-out 0s;
}

#form-signin-container .form-label-group input::placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #eee;
}

@keyframes shrink {
  0% {
    width: 110%;
    height: 110%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes expand {
  0% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 110%;
    height: 110%;
  }
}

.shimmer {
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, #999));
  background: -moz-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, #999));
  background: gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, #999));
  -webkit-background-size: 50% 100%;
  -moz-background-size: 50% 100%;
  background-size: 50% 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff;
}

@-moz-keyframes shimmer {
  0% {
    background-position-x: -200%;
  }
  100% {
      background-position-x: 200%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position-x: -200%;
  }
  100% {
      background-position-x: 200%;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position-x: -200%;
  }
  100% {
      background-position-x: 200%;
  }
}

@keyframes shimmer {
  0% {
      background-position-x: -200%;
  }
  100% {
      background-position-x: 200%;
  }
}
